export const RULES = {
    "adminolivia":{
        "block":[],
        "userName":"OliviaRue",
        "tools": {
            "Dashboard": "/dashboard",
        }
    },
    "portfolio-22":{
        "block":["/dashboard","/work/misc","/work/cuquin",],
        "userName":"Visitor"
    },
    "olivia-22":{
        "block":["/dashboard",],
        "userName":"StandardGuest"
    },
    "web-22":{
        "block":["/dashboard",],
        "userName":"Guest"
    },


    "core-22":{
        "block":["/dashboard",],
        "userName":"CoreAnimation"
    },
    "lbox-22":{
        "block":["/dashboard",],
        "userName":"Lightbox"
    },
    "hampa-22":{
        "block":["/dashboard",],
        "userName":"Hampa"
    },
    "thinkl-22":{
        "block":["/dashboard",],
        "userName":"Thinklab"
    },
    "blows-22":{
        "block":["/dashboard",],
        "userName":"BlowStudio"
    },
    "able-22":{
        "block":["/dashboard",],
        "userName":"Able&Baker"
    },


    "skydance-22":{
        "block":["/dashboard",],
        "userName":"SkydanceAnimation"
    },
    "spa-22":{
        "block":["/dashboard",],
        "userName":"SPA"
    },
    "fortiche-22":{
        "block":["/dashboard",],
        "userName":"Fortiche"
    },

    
    "icon-22":{
        "block":["/dashboard",],
        "userName":"Icon"
    },
    "animal-22":{
        "block":["/dashboard",],
        "userName":"AnimalLogic"
    },
    "sony-22":{
        "block":["/dashboard",],
        "userName":"SonyAnimation"
    },
    "pixar-22":{
        "block":["/dashboard",],
        "userName":"Pixar"
    },
    "disney-22":{
        "block":["/dashboard",],
        "userName":"Disney"
    },
    "dreamworks-22":{
        "block":["/dashboard",],
        "userName":"Dreamworks"
    },
    "illum-22":{
        "block":["/dashboard",],
        "userName":"Illumination"
    },
    "netflix-22":{
        "block":["/dashboard",],
        "userName":"Netflix"
    },
    "mikros-22":{
        "block":["/dashboard",],
        "userName":"Mikros"
    },
    "ndog-22":{
        "block":["/dashboard",],
        "userName":"NaughtyDog"
    },
    "bardel-22":{
        "block":["/dashboard",],
        "userName":"Bardel"
    },
    "bluezoo-22":{
        "block":["/dashboard",],
        "userName":"Blue-Zoo"
    },
    "boulder-22":{
        "block":["/dashboard",],
        "userName":"BoulderMedia"
    },
    "cartnet-22":{
        "block":["/dashboard",],
        "userName":"CartoonNetwork"
    },
    "cartsal-22":{
        "block":["/dashboard",],
        "userName":"CartoonSaloon"
    },
    "kuku-22":{
        "block":["/dashboard",],
        "userName":"KukuStudios"
    },
    "nick-22":{
        "block":["/dashboard",],
        "userName":"Nickelodeon"
    },
    "inef-22":{
        "block":["/dashboard",],
        "userName":"InEfecto"
    },
    "titm-22":{
        "block":["/dashboard",],
        "userName":"Titmouse"
    },
    "blur-22":{
        "block":["/dashboard",],
        "userName":"Blur"
    },
    "jelly-22":{
        "block":["/dashboard",],
        "userName":"Jellyfish"
    },
    "stellar-22":{
        "block":["/dashboard",],
        "userName":"Stellar"
    },
    "wildbrain-22":{
        "block":["/dashboard",],
        "userName":"Wildbrain"
    },
    "blizzard-22":{
        "block":["/dashboard",],
        "userName":"Blizzard"
    },
    "respawn-22":{
        "block":["/dashboard",],
        "userName":"Respawn"
    },
    "atomic-22":{
        "block":["/dashboard",],
        "userName":"AtomicCartoons"
    },
    "laika-22":{
        "block":["/dashboard",],
        "userName":"Laika"
    },
    "locks-22":{
        "block":["/dashboard",],
        "userName":"LockSmith"
    },
    "aardman-22":{
        "block":["/dashboard",],
        "userName":"Aardman"
    },
    "surfing-22":{
        "block":["/dashboard",],
        "userName":"SurfingGiant"
    },
    "ludo-22":{
        "block":["/dashboard",],
        "userName":"LudoStudio"
    },
    "paramount-22":{
        "block":["/dashboard",],
        "userName":"ParamountAnimation"
    },
    "spire-22":{
        "block":["/dashboard",],
        "userName":"SpireAnimation"
    },
    "lighthouse-22":{
        "block":["/dashboard",],
        "userName":"LighthouseStudios"
    },
    "ona-22":{
        "block":["/dashboard",],
        "userName":"OnAnimation"
    },
    "dwarf-24":{
        "block":["/dashboard",],
        "userName":"Dwarf"
    }
}