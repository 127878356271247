import { useState } from "react"
import "./credentialsPopUp.scss"
import {useNavigate } from "react-router-dom"
import { RULES } from '../../settings.js'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';

const CredentialsPopUp = ({currentItem}) => {
    const url = window.location.pathname;
    const project = url.split( '/' ).at(-1);

    const navigate = useNavigate()

    const [error, setError] = useState(false)
    const [correct, setCorrect] = useState(false)

    var [password, setPassword] = useState( localStorage.getItem('password') )
    var [userName, setUserName] = useState( localStorage.getItem('user') || "Anynomus")

    const registerView = async (userName) => {
        var reply = undefined
        try{
            reply = await fetch( ("https://aplauncher.firebaseio.com/LogIn/" + userName + ".json") ,{
                method: 'POST',
                body: JSON.stringify({
                    "date": Date.now(),
                    "search": userName
                })
            })
        } catch {
            reply = undefined
        }
    }

    const handleLogin = async (e) => {        
        e.preventDefault()
        if ( password in RULES ) {
            userName = RULES[password]["userName"]
            setUserName(userName)
            if ( RULES[password]["block"].includes(currentItem ? currentItem.link : url) ) {
                setError("You have no access to this project")
            } else {
                localStorage.setItem('password', password );
                localStorage.setItem('user', userName );
                
                setCorrect(true)
                registerView(userName)
                
                navigate(currentItem ? currentItem.link : url)
                window.location.reload(true);
            }
        } else {
            setError("Wrong password!")
        }
    }

    if (correct) {
        return(
            console.log("DONE")
        )
    } else {
        return (
            <div className="credentialsWindow">
                <h1>
                    <span>Private access</span>
                </h1>

                <form onSubmit={handleLogin}>
                    <input type="password" placeholder=" Password" onChange={e => setPassword(e.target.value)} />
                    <button type="submit">Access</button>
                    {error && <span>{error}</span>}
                    <br/>
                    <label>Ask me for password:</label>
                    <div className="askPassword">

                        <a className="ask" style={{textDecoration:"none"}} href="mailto:olivia.rue.23@gmail.com?subject=Password petition&body=Hi Olivia, can you give me access to your site?">
                            <EmailOutlinedIcon className="icon"/>
                        </a>

                        <a className="ask" href="https://www.linkedin.com/in/oliviarue/">
                            <img className="logo" src="https://cdn-icons-png.flaticon.com/512/174/174857.png" alt=""/>
                        </a>

                        <a className="ask" href="https://www.instagram.com/oliviarue">
                            <img className="logo" src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Instagram_logo_2016.svg/2048px-Instagram_logo_2016.svg.png" alt=""/>
                        </a>

                    </div>
                </form>

            </div>
        )
    }
}

export default CredentialsPopUp