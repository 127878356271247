import "./projectList.scss"
import { React, useState, useEffect} from 'react'
import { HomeGallery } from '../../galleryImg.js'
import { RULES } from '../../settings.js'
import { useNavigate  } from "react-router-dom";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import CredentialsPopUp from '../credentialsPopUp/CredentialsPopUp.jsx'

const ProjectList = () => {
    const url = window.location.pathname;
    const project = url.split( '/' ).at(-1);
    let CurrentGallery = []
    const navigate = useNavigate()

    const [ask, setAsk] = useState(false)
    const [currentItem, setCurrentItem] = useState({})
    const [password, setPassword] = useState(localStorage.getItem('password'))

    if (project === "home") {
        CurrentGallery = HomeGallery
    } else {
        CurrentGallery = false
    }
    const setUnlockedStatus = (url) => {
        if ( password in RULES ) {
            if ( RULES[password]["block"].includes(url) ) {
                return false
            } else {
                return true
            }
        }
        return false
    }

    if ( CurrentGallery != false ) {
        let images = [];
        CurrentGallery.map((img, index) => {
            images.push({
                id:index,
                imgSrc: img.imgHigh,
                imgLow: img.imgLow,
                imgHigh: img.imgHigh,
                description: img.description,
                link: img.link,
                hidden: img.hidden,
                unlocked: setUnlockedStatus(img.link),
            })
        });

        const openProject = (item) => {
            if (item.hidden) {
                if (item.unlocked) {
                    navigate(item.link)
                } else {
                    setCurrentItem(item)
                    setAsk(true)
                }
            } else {
                navigate(item.link)
            }
        }
    
        return (
            <>    
                <div className="ProjectListContainer">
                    <div className={ ask ? "credentialsContainer ask" : "credentialsContainer"}>
                        <div className="credentialBG"  onClick={() => {setAsk(false)}}/>
                        <div className="credentials"><CredentialsPopUp currentItem={currentItem}/></div>
                    </div>
                    
                    <ul className="gallery">
                        {images.map((item, index) =>{
                            return(
                                <li className="imgFrame" key={index} onClick={() => {openProject(item)}}>
    
                                    <img className={ item.hidden ? (item.unlocked ? "img" : "img hidden") : "img"} src={item.imgSrc} alt={item.description} style={{width:'100%', height:'100%'}}/>

                                    <div className={ item.hidden ? "locked" : "locked hidden"}>
                                        <div className={ item.unlocked ? "unLocked true" : "unLocked"}>
                                            <LockOutlinedIcon className="icon"/>
                                        </div>
                                        <div className={ item.unlocked ? "unLocked" : "unLocked true"}>
                                            <LockOpenOutlinedIcon className="icon"/>
                                        </div>
                                    </div>

                                    <div className={ item.unlocked ? "descriptionContent unlocked" : "descriptionContent locked"}>
                                        <label className="description">{ item.hidden ? "Unannounced Project" : item.description}</label>
                                    </div>
                                    <div className={ item.unlocked ? "descriptionContent locked" : "descriptionContent unlocked"}>
                                        <label className="description">{item.description}</label>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </>
        )
    }    
}

export default ProjectList