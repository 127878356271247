import "./chart.scss"
import React, { PureComponent } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const Chart = ({chartList}) => {
  let studios = []
  if ( chartList.length > 0 ) {
    Object.entries(chartList[0]).map( ([studio]) => {
      if ( studio != "date" ) {
        studios.push(studio)
      }
    })
  } 
  if ( chartList.length == 1 ) {
    chartList[1] = chartList[0]
    chartList[2] = chartList[0]
    chartList[1]["date"] = ""
    chartList[2]["date"] = ""
  }

  function generateRandomColor(seed){
    let maxVal = 0xFFFFFF; // 16777215
    let randomNumber = Math.random() * maxVal; 
    randomNumber = Math.floor(randomNumber);
    randomNumber = randomNumber.toString(16);
    let randColor = randomNumber.padStart(6, 0);   
    return `#${randColor.toUpperCase()}`
}
    return (
        <div className="chart">
            <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                    width={500}
                    height={400}
                    data={chartList}
                    margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 0,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    {studios.map( (studio, index) => {
                      return (
                        <Area key={studio} type="monotone" dataKey={studio} stroke={generateRandomColor(index)} fill={generateRandomColor(index)} />
                      )
                    })}
                </AreaChart>
            </ResponsiveContainer>
        </div>
    )
}

export default Chart