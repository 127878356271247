import './dashboard.scss'
import { useState, useEffect } from "react"
import Navbar from '../../components/navbar/Navbar';
import Bottombar from '../../components/bottombar/Bottombar';
import Chart from '../../components/chart/Chart';
import CloseIcon from '@mui/icons-material/Close';
import Gallery from "../../components/gallery/Gallery";
import {RULES} from '../../settings.js'

import { AcrossGallery, CuquinGallery, MiscProjects, PersonalGallery, PreschoolGallery, TitoGallery } from '../../galleryImg.js'


const Dashboard = () => {
    const url = window.location.pathname;
    const section = url.split( '/' )[1];

    const max_gallery = 300

    var [data, setData] = useState({})
    var [searchList, setSearchList] = useState([])
    var [sortedList, setSortedList] = useState([])
    var [chartList, setChartList] = useState([])
    var [sortedGallery, setSortedGallery] = useState([])
    
    var [analyticField, setAnalyticField] = useState("LogIn")

    var [sectionLogs, setSectionLogs] = useState(false)
    var [sectionViews, setSectionViews] = useState(false)
    var [sectionImages, setSectionImages] = useState(false)
    var [sectionStudios, setSectionStudios] = useState(false)

    const handleHightlightSection = (section) => {
        setSectionLogs(false)
        setSectionViews(false)
        setSectionImages(false)
        setSectionStudios(false)

        if (section === "LogIn") {
            setSectionLogs(true)
        } else if (section === "Visitors") {
            setSectionViews(true)
        } else if (section === "Images") {
            setSectionImages(true)
        } else if (section === "Studios") {
            setSectionStudios(true)
        } 
    }
    
    const fetchDataBase = async (analyticField) => {
        var reply = undefined
        try{
            reply = await fetch( ("https://aplauncher.firebaseio.com/" + analyticField + ".json") ,{
                method: 'GET',
            })
            const fetchedData = await reply.json()
            setData(fetchedData)
            sortViews(fetchedData, "")
            
        } catch {
            // Do nothing
        }
        handleHightlightSection(analyticField)
    }

    useEffect(() => {
        fetchDataBase(analyticField)
      }, [analyticField])

    useEffect(() => {
        if (sectionImages) {
            getGallery(sortedList, "")
        }
      }, [sortedList])

    

    const getGallery = (data, filter) => {
        let SortedImageViews = []

        data.map( (element) => {
            Object.entries(element).map( ([name, Value]) => {
                if (name.includes("-")) {
                    var gallery = name.split("-")[0]
                    var id = name.split("-")[1]
            
                    var imageDict = {}
                    let CurrentGallery = []

                    if (gallery === "across") {
                        CurrentGallery = AcrossGallery
                    } else if (gallery === "cuquin") {
                        CurrentGallery = CuquinGallery
                    } else if (gallery === "misc") {
                        CurrentGallery = MiscProjects
                    } else if (gallery === "personal") {
                        CurrentGallery = PersonalGallery
                    } else if (gallery === "preschool") {
                        CurrentGallery = PreschoolGallery
                    } else if (gallery === "tito") {
                        CurrentGallery = TitoGallery
                    } else {
                        CurrentGallery = false
                    }
            
                    if (!CurrentGallery === false) {
                        imageDict = CurrentGallery[id]
                        SortedImageViews.push(imageDict)
                        setSortedGallery(SortedImageViews)
                    }
                }
            })
        })
    }

    const sortViews = (data, filter, max_number = max_gallery, group_type = "date") => {
        var keys = []
        
        Object.keys(data).map( (element) => {
            if ((filter.length == 0 ) || JSON.stringify(data[element]).toLowerCase().includes(filter.toLowerCase()) ) {
                var length = Object.keys( data[element]).length
                keys.push( [length, element] )
            }
        });
        var i, len = keys.length;
        
        keys.sort(function(first, second) {
            return second[0] - first[0];
          });

        let searchList = [];
        let sortedList = [];
        for (i = 0; i < Math.min(len, max_number); i++)
        {
            var name = keys[i][1]
            var views = keys[i][0]
            var dict = data[keys[i][1]]

            var elementCounter = {}
            Object.keys(dict).map( (view) => {
                var searchValue = dict[view].search
                if (JSON.stringify(searchList).toLowerCase().includes(searchValue.toLowerCase()) === false) {
                    searchList.push(searchValue)
                }

                var group = ""
                if (group_type === "date") {
                    group = (new Date(dict[view].date)).toDateString()
                }

                var group_counter = 1
                if (group in elementCounter) {
                    group_counter = elementCounter[group] + 1
                }
                elementCounter = ({ ...elementCounter, [group]: group_counter} );
            })
            sortedList.push( { [name]: elementCounter} );
        }

        // Set sortedList for gallery
        setSearchList(searchList)
        setSortedList(sortedList)

        // Group by date
        var chartDict = {}
        sortedList.map( (element) => {
            Object.entries(element).map( ([name, Value]) => {
                Object.entries(Value).map( ([day, views]) => {
                    if (day in chartDict === false) {
                        chartDict[day] = {[name]:views}
                    } else {
                        chartDict[day] = ({ ...chartDict[day], [name]:views} );
                    }
                })
            })
        })


        // DATA STRUCTURE
        // const data = [
        //     {
        //       "day": "25/10/2022",
        //       "image-01": 20,
        //       "image-02": 10,
        //     },
        //     {
        //       "day": "26/10/2022",
        //       "image-01": 12,
        //       "image-02": 14,
        //     }
        // ]

        let chartList = []
        Object.entries(chartDict).map( ([date, data]) => {
            var dict = {}
            dict["date"] = date

            Object.entries(data).map( ([name, views]) => {
                dict[name] = views
            })

            // Set also images with 0 views for better visualization in chart
            sortedList.map( (element) => {
                Object.entries(element).map( ([name, Value]) => {
                    if (name in dict === false){
                        dict[name] = 0
                    }
                })
            })

            
            chartList.push(dict)
        })

        setChartList(chartList)
        chartList = chartList.sort(function(a, b) {
            var c = new Date(a.date);
            var d = new Date(b.date);
            return c-d;
        });

        return chartList
    }

    const handleInputChange = (e) => {
        sortViews(data, e.currentTarget.value)
    }

    const handleRemoveFilter = (e) => {
        document.getElementById("filterBy").value = "";
        sortViews(data, "")
    }

    const handleSelection = (value) => {
        document.getElementById("filterBy").value = value;
        sortViews(data, value)
    }

    return (
        <div className="page">

            <div className="top">
                <Navbar/>
            </div>

            <div className="mainContainer">
                <div className="content">

                    <div className="dashboard">

                        <div className="AnalyticSelector">
                            <hr />
                            <div className="sections">
                            
                                <div className={sectionLogs? "section true" : "section"} onClick={ () => {fetchDataBase("LogIn")} }>
                                    <p>LogIn</p>
                                </div>

                                <div className={sectionViews? "section true" : "section"} onClick={ () => {fetchDataBase("Visitors")} }>
                                    <p>VIEWS</p>
                                </div>

                                <div className={sectionImages? "section true" : "section"} onClick={ () => {fetchDataBase("Images")} }>
                                    <p>IMAGES</p>
                                </div>

                                <div className={sectionStudios? "section true" : "section"} onClick={ () => {handleHightlightSection("Studios")} }>
                                    <p>STUDIOS</p>
                                </div>
                            </div>
                        </div>


                        <div className={sectionStudios? "AnalyticChart false" : "AnalyticChart true"}>
                            <div className="filterDiv">
                                <label htmlFor="">{sectionLogs? "Filter views by page " : sectionViews? "" : "Filter views by element "}</label>
                                <input type="text" id="filterBy" placeholder={sectionLogs? "/work" : sectionViews? "" : "Skydance"} onChange={ handleInputChange} />
                                <CloseIcon className="icon" onClick={handleRemoveFilter}/>
                            </div>
                            <div className="filterOptions">
                                {searchList.map( (searchValue, index) => {
                                    return (
                                        <div className="filterOption" onClick={ () => {handleSelection(searchValue)}}>
                                            <p>{searchValue}</p>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="chart">
                                <Chart chartList={chartList}/>
                            </div>
                        </div>

                        <div className={sectionStudios? "StudiosList True" : "StudiosList false"}>
                            <div className="studios">
                                {Object.entries(RULES).map( ([password, dict]) => {
                                    return (
                                        <div className="filterOption">
                                            <p>{dict.userName} = {password}</p>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>

                        <div className={sectionImages? "gallery true" : "gallery false"}>
                            <Gallery GivenGallery={sortedGallery}/>
                        </div>
                    </div>

                </div>

                <div className="bottom">
                    <Bottombar/>
                </div>

            </div>
        </div>
    );
};


export default Dashboard