import './navbar.scss'
import { React, useState, useEffect} from 'react'
import { useNavigate  } from "react-router-dom";

import { RULES } from '../../settings.js'
import { ContactGallery } from '../../galleryImg.js'
import CredentialsPopUp from '../credentialsPopUp/CredentialsPopUp.jsx'
import SectionContextMenu from '../sectionContextMenu/SectionContextMenu.jsx'

import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';

const Navbar = () => {
    const url = window.location.pathname;
    const section = url.split( '/' )[1];
    const navigate = useNavigate()

    const [ask, setAsk] = useState(false)
    // A snippet of code which runs bases on a specific conditions
    var [password, setPassword] = useState( localStorage.getItem('password') )
    var [userName, setUserName] = useState( localStorage.getItem('user') || 'Anynomus')
    let [tools, setTools] = useState( {} )
    const [contextMenu, setContextMenu] = useState(false)


    useEffect(() => {
        if ( password in RULES ) {
            userName = RULES[password]["userName"]
            setUserName( userName )
            if ( "tools" in RULES[password] ) {
                setTools( RULES[password]["tools"] )
            }
        }
    }, [url])

    const handleLogout = ((e) => {
        setContextMenu(false)
        localStorage.removeItem('password')
        localStorage.removeItem('user')
        window.location.reload(true);
    })

    const clickProfileButton = ((e) => {
        if (userName === "Anynomus") {
            setAsk(true)
        } else {
            setContextMenu(contextMenu ? false : true)
        }
    })


    const [menu, setMenu] = useState(false);

    var sectionHome = false
    var sectionWork = false
    var sectionPersonal = false
    var sectionContact = false

    if (section === "") {
        sectionHome = true
    } else if (section === "work") {
        sectionWork = true
    } else if (section === "personal") {
        sectionPersonal = true
    } else if (section === "contact") {
        sectionContact = true
    }

    return (
        <div className="navbar">
            <div className={ ask ? "credentialsContainer ask" : "credentialsContainer"}>
                <div className="credentialBG"  onClick={() => {setAsk(false)}}/>
                <div className="credentials"><CredentialsPopUp /></div>
            </div>
            
            <div className="top">

                <div className="logo">
                    <a href="/home">
                        <img className="mainLogo" src={ContactGallery[1].imgHigh} alt="" />
                    </a>
                </div>

                <div className="sections">
                    <div className="section">
                        <SectionContextMenu section={"home"}/>
                    </div>

                    <div className="section">
                        <SectionContextMenu section={"work"}/>
                    </div>
                    
                    <div className="section">
                        <SectionContextMenu section={"personal"}/>
                    </div>

                    <div className="section">
                        <SectionContextMenu section={"contact"}/>
                    </div>

                </div>

                <div className="right">
                    <div className={ userName ? "login true" : "login" }>

                        <div className="loginButton" onClick = {() => clickProfileButton()}>
                            <AccountCircleOutlinedIcon className='icon'/>
                            <h1>{userName ? (userName === true ? "LogIn" : userName) : "LogIn"}</h1>
                        </div>

                        <div className={contextMenu ? "contextMenu true" : "contextMenu" }>
                            {Object.entries(tools).map( ([section, link]) =>{
                                return(
                                    <h1 key={section} className='option' onClick = {() => navigate(link)}>{section}</h1>
                                )
                            })}
                            
                            <h1 className='option' onClick = {() => handleLogout()}>Logout</h1>
                        </div>

                    </div>
                    
                    <div className="social">
                        <a href="https://www.linkedin.com/in/oliviarue/">
                            <img src="https://cdn-icons-png.flaticon.com/512/174/174857.png" alt="" className="socialLogo"/>
                        </a>
                        <a href="https://www.instagram.com/oliviarue">
                            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Instagram_logo_2016.svg/2048px-Instagram_logo_2016.svg.png" alt="" className="socialLogo"/>
                        </a>
                    </div>

                    <div className="menuToggle">
                        <div className={menu? "menuToggle open" : "menuToggle"}>
                            <MenuRoundedIcon className="icon" onClick = {() => setMenu(true)}/>
                        </div>
                        <div className={menu? "menuClose open" : "menuClose"}>
                            <CloseRoundedIcon className="icon" onClick = {() => setMenu(false)}/>
                        </div>
                    </div>
                </div>



            </div>

            <div className="optionMenu">

                <div className={menu? "menu open" : "menu"}>
                    <div className="sections">
                        <div className="section">
                            <SectionContextMenu section={"home"}/>
                        </div>

                        <div className="section">
                            <SectionContextMenu section={"work"}/>
                        </div>
                        <div className="section">
                            <SectionContextMenu section={"personal"}/>
                        </div>

                        <div className="section">
                            <SectionContextMenu section={"contact"}/>
                        </div>
                    </div> 

                    <div className="social">
                        <a href="https://www.linkedin.com/in/oliviarue/">
                            <img src="https://cdn-icons-png.flaticon.com/512/174/174857.png" alt="" className="socialLogo"/>
                        </a>
                        <a href="https://www.instagram.com/oliviarue">
                            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Instagram_logo_2016.svg/2048px-Instagram_logo_2016.svg.png" alt="" className="socialLogo"/>
                        </a>
                    </div>

                </div>
            </div>

        </div>
    );
};


export default Navbar