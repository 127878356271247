import Home from "./pages/home/Home";
import Project from "./pages/project/Project";
import NoMatch from "./pages/noMatch/NoMatch";
import Contact from "./pages/contact/Contact";
import Credentials from "./pages/credentials/Credentials";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { React, useState, useEffect} from 'react'

import "./styles/mainStyle.scss"
// import "./styles/mobileStyle.scss"
import Dashboard from "./pages/dashboard/Dashboard";


function App() {
  var userName = useState( localStorage.getItem('user') || 'Anynomus')
  const url = window.location.pathname;

  var isMobile = {
    Android: function() {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function() {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function() {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function() {
        return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
    },
    any: function() {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
    }
  };

  const registerView = async () => {
    if (["/dashboard"].includes(url) === false) {
        var reply = undefined
        try{
            reply = await fetch( ("https://aplauncher.firebaseio.com/Views/" + url + ".json") ,{
                method: 'POST',
                body: JSON.stringify({
                    "date": Date.now(),
                    "search": userName
                })
            })
        } catch {
            reply = undefined
        }
    } 
  }

  useEffect(() => {
    registerView()
  }, [url])

  
  return (
    <div className={isMobile.any() ? "app mobile" : "app"}>
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route index element={<NoMatch/>} />
            
            <Route path="home" element={<Home/>} />
            <Route path="credentials" element={<Credentials/>} />
            <Route path="dashboard" element={<Credentials> <Dashboard/> </Credentials>} />
            <Route path="contact" element={<Contact/>}/>
            <Route path="*" element={<Project/>} />

            <Route path="work/tito" element={<Project/>}/>
            <Route path="work/preschool" element={<Project/>}/>
            <Route path="work/cuquin" element={<Credentials> <Project/> </Credentials> }/>
            <Route path="work/misc" element={<Credentials> <Project/> </Credentials> }/>
            <Route path="work/across" element={<Project/>}/>

            <Route path="personal" element={<Project/>}/>
            <Route path="illustration" element={<Project/>}/>

          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
