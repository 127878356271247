import { useState, useEffect } from "react"
import "./credentials.scss"
import {useNavigate } from "react-router-dom"
import CredentialsPopUp from "../../components/credentialsPopUp/CredentialsPopUp"
import Home from "../home/Home"
import { RULES } from '../../settings.js'

const Credentials = ({children}) => {
    const url = window.location.pathname;
    const navigate = useNavigate()
    
    // A snippet of code which runs bases on a specific conditions
    const [password, setPassword] = useState(localStorage.getItem('password'))
    const [unlocked, setUnlocked] = useState(false)
    useEffect(() => {
        if ( password in RULES ) {
            if ( RULES[password]["block"].includes(url) ) {
                console.log("")
            } else {
                setUnlocked(true)
            }
        }
    }, [url])

    
    if (unlocked) {
        return(
            children
        )
    } else {
        return (
            <div>
                <Home/>
                <div className="credentialsContainer ask">
                    <div className="credentialBG"  onClick={() => {navigate("/")}}/>
                    <div className="credentials"><CredentialsPopUp/></div>
                </div>
            </div>
        )
    }
}

export default Credentials