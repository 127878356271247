import './project.scss'
import Navbar from '../../components/navbar/Navbar';
import Bottombar from '../../components/bottombar/Bottombar';
import Gallery from "../../components/gallery/Gallery";

const Project = () => {
    return (
        <div className="page">

            <div className="top">
                <Navbar/>
            </div>

            <div className="mainContainer">

                <div className="content">
                    <Gallery/>
                </div>

                <div className="bottom">
                    <Bottombar/>
                </div>

            </div>
        </div>
    );
};


export default Project