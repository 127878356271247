import "./gallery.scss"
import { React, useState} from 'react'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { AcrossGallery, CuquinGallery, MiscProjects, PersonalGallery, PreschoolGallery, TitoGallery, LogoGallery } from '../../galleryImg.js'
import { Navigate } from "react-router-dom";

const Gallery = ({GivenGallery}) => {
    var [password, setPassword] = useState( localStorage.getItem('password') )
    var [userName, setUserName] = useState( localStorage.getItem('user') || 'Anynomus')
    const url = window.location.pathname;
    const project = url.split( '/' ).at(-1);
    let CurrentGallery = []
    let logoImage = false
    let logoImage2 = false
    const [model, setModel] = useState(false);
    const [tempImgIndex, setTempImgIndex] = useState(0);
    const [tempImgSrc, setTempImgSrc] = useState('');

    if (GivenGallery) {
        CurrentGallery = GivenGallery
    } else {
        if (project === "across") {
            CurrentGallery = AcrossGallery
        } else if (project === "cuquin") {
            CurrentGallery = CuquinGallery
        } else if (project === "misc") {
            CurrentGallery = MiscProjects
        } else if (project === "personal") {
            CurrentGallery = PersonalGallery
        } else if (project === "preschool") {
            CurrentGallery = PreschoolGallery
        } else if (project === "tito") {
            CurrentGallery = TitoGallery
        } else {
            CurrentGallery = false
        }

        LogoGallery.map((img, index) => {
            if (img.section === project) {
                if ( ["misc", "preschool"].includes(project) ) {
                    logoImage = img
                } else {
                    logoImage2 = img
                }
            }
        })
    }

    const registerView = async (item) => {
        if (["/dashboard"].includes(url) === false) {
            var reply = undefined
            try{
                reply = await fetch( ("https://aplauncher.firebaseio.com/Images/" + item + ".json") ,{
                    method: 'POST',
                    body: JSON.stringify({
                        "date": Date.now(),
                        "search": userName
                    })
                })
            } catch {
                reply = undefined
            }
        } 
    }


    if ( CurrentGallery != false ) {
        let images = [];
        CurrentGallery.map((img, index) => {
            images.push({
                id:index,
                imgSrc: img.imgLow,
                imgLow: img.imgLow,
                imgHigh: img.imgHigh,
                description: img.description,
            })
        });
    
    
        const closeImg = () => {
            setModel(false)
            setTempImgSrc("");
            setTempImgIndex(0);
        }

        const getImg = (index) => {
            setTempImgIndex(index);
            setTempImgSrc(images[index].imgHigh);
            setModel(true)
            registerView(project + "-" + index)
        }
    
        const getOther = (offset) => {
            var newID = tempImgIndex + offset
            if (newID >= images.length) {
                newID = newID - (images.length)
            }
            if (newID < 0) {
                newID = newID + (images.length)
            }
            const newSrc = images[newID].imgHigh;
            registerView(project + "-" + newID)
            setTempImgSrc(newSrc);
            setTempImgIndex(newID);
        }
    
        document.onkeydown = (event) => {
            if (model === true) {
                var name = event.key;
                if (name === "Escape") {
                    closeImg()
                } else if (name === "ArrowLeft") {
                    getOther(-1)
                } else if (name === "ArrowRight") {
                    getOther(1)
                }
                
                console.log("CHECK KEY: " + name)
            }
        };
    
        return (
            <>
                <div className={model? "model open" : "model"}>
                    <div className="buttons">
                        <CloseRoundedIcon className="close" onClick={() => closeImg()}/>
                        <ArrowBackIosNewRoundedIcon className="back" onClick={() => getOther(-1)}/>
                        <ArrowForwardIosRoundedIcon className="forward" onClick={() => getOther(1)}/>
                    </div>
                    <div className={model? "mainImg open" : "mainImg"}>
                        <img src={tempImgSrc} alt="" />
                        <label className="description">{images[tempImgIndex].description}</label>
                    </div>
                    <div className="bg" onClick={() => closeImg()}>
                    </div>
                </div>
    
                <div className="galleryContainer">
                    <div className="galleryTitle">
                        <img className="logoProject" src={logoImage.imgHigh} alt=""/>
                        <img className="logoProject2" src={logoImage2.imgHigh} alt=""/>
                    </div>
    
                    <ul className="gallery">
                        {images.map((item, index) =>{
                            return(
                                <li className="imgFrame" key={index} onClick={() => getImg(index)}>
                                    <img className="img" src={item.imgSrc} alt={item.description} style={{width:'100%', height:'100%'}}/>
                                    <label className="description">{item.description}</label>
                                </li>
                            )
                        })}
    
                    </ul>
                </div>
            </>
        )
    }
    return (
        <h1>Gallery not found</h1>
    )
    
}

export default Gallery