import './home.scss'
import Navbar from '../../components/navbar/Navbar';
import Bottombar from '../../components/bottombar/Bottombar';
import ProjectList from "../../components/projectList/ProjectList";

const Home = () => {
    return (
        <div className="page">

            <div className="top">
                <Navbar/>
            </div>


            <div className="mainContainer">

                <div className="content">
                    <ProjectList/>
                </div>
                
                <div className="bottom">
                    <Bottombar/>
                </div>

            </div>
        </div>
    );
};


export default Home