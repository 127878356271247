import './bottombar.scss'
import { React, useState} from 'react'

const Bottombar = () => {
    const [menu, setMenu] = useState(false);


    return (
        <div className="bottombar">
            <div className="sections">
                <div className="section">
                    OLIVIA PEÑA RUE 2024
                </div>
                <div className="section">
                    <a href="/"style={{textDecoration:"none", color:"black"}}>
                        HOME
                    </a>
                </div>
            </div>
        </div>
    );
};


export default Bottombar