import './contact.scss'
import Navbar from '../../components/navbar/Navbar';
import Bottombar from '../../components/bottombar/Bottombar';
import { ContactGallery } from '../../galleryImg.js'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';

const Contact = () => {
    return (
        <div className="page">

            <div className="top">
                <Navbar/>
            </div>

            <div className="mainContainer">
                <div className="content">

                    <div className="contentProfile">
                        <div className="profilePic">
                            <img className="profileImg" src={ContactGallery[0].imgHigh} alt="" />
                        </div>
                        <div className="profileDesc">
                            <h1>Hello!</h1>
                            <br />
                            <p>I am a Visual Development Artist, Character Design and Illustrator with 5 years of experience.</p>
                            <p>I am currently working in the animation industry as a Visual Development Artist at Ánima Studios.</p> 
                            <p>I love telling stories through compelling designs, light and color tailored for both young and adult audiences.</p>
                            <p>I am always looking for opportunities that will not only challenge me to use my abilities and acquired skills but also develop new ones as well.</p>
                            <br/>
                            <p>For freelance work or any inquiries feel free to contact me:</p>

                            <div className="contactButtons">
                                <a className="ask" style={{textDecoration:"none"}} href="mailto:olivia.rue.23@gmail.com?subject=Hi Olivia">
                                    <EmailOutlinedIcon className="icon"/>
                                </a>

                                <a className="ask" href="https://www.linkedin.com/in/oliviarue/">
                                    <img className="logo" src="https://cdn-icons-png.flaticon.com/512/174/174857.png" alt=""/>
                                </a>

                                <a className="ask" href="https://www.instagram.com/oliviarue">
                                    <img className="logo" src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Instagram_logo_2016.svg/2048px-Instagram_logo_2016.svg.png" alt=""/>
                                </a>
                            </div>

                        </div>
                    </div>

                </div>

                <div className="bottom">
                    <Bottombar/>
                </div>

            </div>
        </div>
    );
};


export default Contact