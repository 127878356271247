export const HomeGallery = [
    {
        imgLow: require("./img/Tito/tito_concept_01_low.jpg"),
        imgHigh: require("./img/Tito/tito_concept_01_high.jpg"),
        description: ( "Tito Lizzardo" ),
        hidden: false,
        section: "work",
        link: "/work/tito"
    },
    {
        imgLow: require("./img/Thumbnails/Preschool_Thumbnail_low.jpg"),
        imgHigh: require("./img/Thumbnails/Preschool_Thumbnail_high.jpg"),
        description: ( "Preschool" ),
        hidden: false,
        section: "work",
        link: "/work/preschool"
    },
    {
        imgLow: require("./img/Personal/personal_concept_12_low.jpg"),
        imgHigh: require("./img/Personal/personal_concept_12_high.jpg"),
        description: ( "Personal" ),
        hidden: false,
        section: "personal",
        link: "/personal"
    },
    {
        imgLow: require("./img/Cuquin/cqn_concept_12b_low.jpg"),
        imgHigh: require("./img/Cuquin/cqn_concept_12b_high.jpg"),
        description: ( "Cuquin" ),
        hidden: true,
        section: "work",
        link: "/work/cuquin"
    },
    {
        imgLow: require("./img/MiscProjects/misc_concept_01_low.jpg"),
        imgHigh: require("./img/MiscProjects/misc_concept_01_high.jpg"),
        description: ( "Misc Projects" ),
        hidden: true,
        section: "work",
        link: "/work/misc"
    },
    {
        imgLow: require("./img/Across/across_concept_01_low.jpg"),
        imgHigh: require("./img/Across/across_concept_01_high.jpg"),
        description: ( "Across the road" ),
        hidden: false,
        section: "work",
        link: "/work/across"
    }
];

export const LogoGallery = [
    {
        imgLow: require("./img/Logos/across_logo_low.png"),
        imgHigh: require("./img/Logos/across_logo_low.png"),
        section: "across",
    },
    {
        imgLow: require("./img/Logos/cuquin_logo_low.png"),
        imgHigh: require("./img/Logos/cuquin_logo_low.png"),
        section: "cuquin",
    },
    {
        imgLow: require("./img/Logos/misc_logo_low.png"),
        imgHigh: require("./img/Logos/misc_logo_high.png"),
        section: "misc",
    },
    {
        imgLow: require("./img/Logos/personal_logo_low.png"),
        imgHigh: require("./img/Logos/personal_logo_low.png"),
        section: "personal",
    },
    {
        imgLow: require("./img/Logos/preschool_logo_low.png"),
        imgHigh: require("./img/Logos/preschool_logo_high.png"),
        section: "preschool",
    },
    {
        imgLow: require("./img/Logos/tito_logo_low.png"),
        imgHigh: require("./img/Logos/tito_logo_low.png"),
        section: "tito",
    }   
];

export const ContactGallery = [
    {
        imgLow: require("./img/Contact/olivia_profile_low.jpg"),
        imgHigh: require("./img/Contact/olivia_profile_high.jpg"),
        description: ( "OliviaProfilePicture" ),
        hidden: false,
        link: "/contact"
    },
    {
        imgLow: require("./img/Contact/Olivia_Logo_low.png"),
        imgHigh: require("./img/Contact/Olivia_Logo_high.png"),
        description: ( "OliviaLogo" ),
        hidden: false,
        link: "/home"
    }
];

export const AcrossGallery = [
    {
        imgLow: require("./img/Across/across_concept_01_low.jpg"),
        imgHigh: require("./img/Across/across_concept_01_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Across/across_concept_02_low.jpg"),
        imgHigh: require("./img/Across/across_concept_02_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Across/across_concept_03_low.jpg"),
        imgHigh: require("./img/Across/across_concept_03_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Across/across_concept_04_low.jpg"),
        imgHigh: require("./img/Across/across_concept_04_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Across/across_concept_05_low.jpg"),
        imgHigh: require("./img/Across/across_concept_05_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Across/across_concept_06_low.jpg"),
        imgHigh: require("./img/Across/across_concept_06_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Across/across_concept_07_low.jpg"),
        imgHigh: require("./img/Across/across_concept_07_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Across/across_concept_08_low.jpg"),
        imgHigh: require("./img/Across/across_concept_08_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Across/across_concept_09_low.jpg"),
        imgHigh: require("./img/Across/across_concept_09_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Across/across_concept_10_low.jpg"),
        imgHigh: require("./img/Across/across_concept_10_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Across/across_concept_11_low.jpg"),
        imgHigh: require("./img/Across/across_concept_11_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Across/across_concept_12_low.jpg"),
        imgHigh: require("./img/Across/across_concept_12_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Across/across_concept_13_low.jpg"),
        imgHigh: require("./img/Across/across_concept_13_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Across/across_concept_14_low.jpg"),
        imgHigh: require("./img/Across/across_concept_14_high.jpg"),
        description: ( "" )
    }
];

export const CuquinGallery = [
    {
        imgLow: require("./img/Cuquin/cqn_concept_11_low.jpg"),
        imgHigh: require("./img/Cuquin/cqn_concept_11_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Cuquin/cqn_concept_12_low.jpg"),
        imgHigh: require("./img/Cuquin/cqn_concept_12_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Cuquin/cqn_concept_12b_low.jpg"),
        imgHigh: require("./img/Cuquin/cqn_concept_12b_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Cuquin/cqn_concept_13_low.jpg"),
        imgHigh: require("./img/Cuquin/cqn_concept_13_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Cuquin/cqn_concept_07b_low.jpg"),
        imgHigh: require("./img/Cuquin/cqn_concept_07b_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Cuquin/cqn_concept_08_low.jpg"),
        imgHigh: require("./img/Cuquin/cqn_concept_08_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Cuquin/cqn_concept_20_low.jpg"),
        imgHigh: require("./img/Cuquin/cqn_concept_20_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Cuquin/cqn_concept_09_low.jpg"),
        imgHigh: require("./img/Cuquin/cqn_concept_09_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Cuquin/cqn_concept_10_low.jpg"),
        imgHigh: require("./img/Cuquin/cqn_concept_10_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Cuquin/cqn_concept_06_low.jpg"),
        imgHigh: require("./img/Cuquin/cqn_concept_06_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Cuquin/cqn_concept_07_low.jpg"),
        imgHigh: require("./img/Cuquin/cqn_concept_07_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Cuquin/cqn_concept_13c_low.jpg"),
        imgHigh: require("./img/Cuquin/cqn_concept_13c_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Cuquin/cqn_concept_13b_low.jpg"),
        imgHigh: require("./img/Cuquin/cqn_concept_13b_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Cuquin/cqn_concept_14_low.jpg"),
        imgHigh: require("./img/Cuquin/cqn_concept_14_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Cuquin/cqn_concept_15_low.jpg"),
        imgHigh: require("./img/Cuquin/cqn_concept_15_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Cuquin/cqn_concept_01_low.jpg"),
        imgHigh: require("./img/Cuquin/cqn_concept_01_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Cuquin/cqn_concept_02_low.jpg"),
        imgHigh: require("./img/Cuquin/cqn_concept_02_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Cuquin/cqn_concept_03_low.jpg"),
        imgHigh: require("./img/Cuquin/cqn_concept_03_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Cuquin/cqn_concept_04_low.jpg"),
        imgHigh: require("./img/Cuquin/cqn_concept_04_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Cuquin/cqn_concept_05_low.jpg"),
        imgHigh: require("./img/Cuquin/cqn_concept_05_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Cuquin/cqn_concept_18_low.jpg"),
        imgHigh: require("./img/Cuquin/cqn_concept_18_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Cuquin/cqn_concept_17_low.jpg"),
        imgHigh: require("./img/Cuquin/cqn_concept_17_high.jpg"),
        description: ( "" )
    }, 
    {
        imgLow: require("./img/Cuquin/cqn_concept_16_low.jpg"),
        imgHigh: require("./img/Cuquin/cqn_concept_16_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Cuquin/cqn_concept_15c_low.jpg"),
        imgHigh: require("./img/Cuquin/cqn_concept_15c_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Cuquin/cqn_concept_19_low.jpg"),
        imgHigh: require("./img/Cuquin/cqn_concept_19_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Cuquin/cqn_concept_15b_low.jpg"),
        imgHigh: require("./img/Cuquin/cqn_concept_15b_high.jpg"),
        description: ( "" )
    },
];

export const MiscProjects = [
    {
        imgLow: require("./img/MiscProjects/misc_concept_01_low.jpg"),
        imgHigh: require("./img/MiscProjects/misc_concept_01_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/MiscProjects/misc_concept_02_low.jpg"),
        imgHigh: require("./img/MiscProjects/misc_concept_02_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/MiscProjects/misc_concept_03_low.jpg"),
        imgHigh: require("./img/MiscProjects/misc_concept_03_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/MiscProjects/misc_concept_04_low.jpg"),
        imgHigh: require("./img/MiscProjects/misc_concept_04_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/MiscProjects/misc_concept_05_low.jpg"),
        imgHigh: require("./img/MiscProjects/misc_concept_05_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/MiscProjects/misc_concept_06_low.jpg"),
        imgHigh: require("./img/MiscProjects/misc_concept_06_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/MiscProjects/misc_concept_07_low.jpg"),
        imgHigh: require("./img/MiscProjects/misc_concept_07_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/MiscProjects/misc_concept_24_low.png"),
        imgHigh: require("./img/MiscProjects/misc_concept_24_high.png"),
        description: ( "" )
    },
    {
        imgLow: require("./img/MiscProjects/misc_concept_08_low.jpg"),
        imgHigh: require("./img/MiscProjects/misc_concept_08_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/MiscProjects/misc_concept_09_low.jpg"),
        imgHigh: require("./img/MiscProjects/misc_concept_09_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/MiscProjects/misc_concept_10_low.jpg"),
        imgHigh: require("./img/MiscProjects/misc_concept_10_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/MiscProjects/misc_concept_11_low.jpg"),
        imgHigh: require("./img/MiscProjects/misc_concept_11_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/MiscProjects/misc_concept_12_low.jpg"),
        imgHigh: require("./img/MiscProjects/misc_concept_12_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/MiscProjects/misc_concept_13_low.jpg"),
        imgHigh: require("./img/MiscProjects/misc_concept_13_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/MiscProjects/misc_concept_14_low.jpg"),
        imgHigh: require("./img/MiscProjects/misc_concept_14_high.jpg"),
        description: ( "" )
    },    {
        imgLow: require("./img/MiscProjects/misc_concept_15_low.jpg"),
        imgHigh: require("./img/MiscProjects/misc_concept_15_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/MiscProjects/misc_concept_16_low.jpg"),
        imgHigh: require("./img/MiscProjects/misc_concept_16_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/MiscProjects/misc_concept_23_low.png"),
        imgHigh: require("./img/MiscProjects/misc_concept_23_high.png"),
        description: ( "" )
    },
    {
        imgLow: require("./img/MiscProjects/misc_concept_17_low.jpg"),
        imgHigh: require("./img/MiscProjects/misc_concept_17_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/MiscProjects/misc_concept_18_low.jpg"),
        imgHigh: require("./img/MiscProjects/misc_concept_18_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/MiscProjects/misc_concept_19_low.jpg"),
        imgHigh: require("./img/MiscProjects/misc_concept_19_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/MiscProjects/misc_concept_20_low.jpg"),
        imgHigh: require("./img/MiscProjects/misc_concept_20_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/MiscProjects/misc_concept_21_low.jpg"),
        imgHigh: require("./img/MiscProjects/misc_concept_21_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/MiscProjects/misc_concept_22_low.jpg"),
        imgHigh: require("./img/MiscProjects/misc_concept_22_high.jpg"),
        description: ( "" )
    }
];

export const PersonalGallery = [
    {
        imgLow: require("./img/Personal/personal_concept_01_low.png"),
        imgHigh: require("./img/Personal/personal_concept_01_high.png"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Personal/personal_concept_02_low.png"),
        imgHigh: require("./img/Personal/personal_concept_02_high.png"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Personal/personal_concept_03_low.png"),
        imgHigh: require("./img/Personal/personal_concept_03_high.png"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Personal/personal_concept_04_low.png"),
        imgHigh: require("./img/Personal/personal_concept_04_high.png"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Personal/personal_concept_05_low.jpg"),
        imgHigh: require("./img/Personal/personal_concept_05_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Personal/personal_concept_06_low.png"),
        imgHigh: require("./img/Personal/personal_concept_06_high.png"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Personal/personal_concept_07_low.jpg"),
        imgHigh: require("./img/Personal/personal_concept_07_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Personal/personal_concept_08_low.png"),
        imgHigh: require("./img/Personal/personal_concept_08_high.png"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Personal/personal_concept_09_low.png"),
        imgHigh: require("./img/Personal/personal_concept_09_high.png"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Personal/personal_concept_10_low.png"),
        imgHigh: require("./img/Personal/personal_concept_10_high.png"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Personal/personal_concept_11_low.jpg"),
        imgHigh: require("./img/Personal/personal_concept_11_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Personal/personal_concept_12_low.jpg"),
        imgHigh: require("./img/Personal/personal_concept_12_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Personal/personal_concept_13_low.png"),
        imgHigh: require("./img/Personal/personal_concept_13_high.png"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Personal/personal_concept_14_low.jpg"),
        imgHigh: require("./img/Personal/personal_concept_14_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Personal/personal_concept_15_low.png"),
        imgHigh: require("./img/Personal/personal_concept_15_high.png"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Personal/personal_concept_16_low.png"),
        imgHigh: require("./img/Personal/personal_concept_16_high.png"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Personal/personal_concept_17_low.png"),
        imgHigh: require("./img/Personal/personal_concept_17_high.png"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Personal/personal_concept_18_low.png"),
        imgHigh: require("./img/Personal/personal_concept_18_high.png"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Personal/personal_concept_19_low.png"),
        imgHigh: require("./img/Personal/personal_concept_19_high.png"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Personal/personal_concept_20_low.jpg"),
        imgHigh: require("./img/Personal/personal_concept_20_high.jpg"),
        description: ( "" )
    }    
];

export const PreschoolGallery = [
    {
        imgLow: require("./img/Preschool/preschool_concept_01_low.jpg"),
        imgHigh: require("./img/Preschool/preschool_concept_01_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Preschool/preschool_concept_02_low.jpg"),
        imgHigh: require("./img/Preschool/preschool_concept_02_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Preschool/preschool_concept_03_low.jpg"),
        imgHigh: require("./img/Preschool/preschool_concept_03_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Preschool/preschool_concept_04_low.jpg"),
        imgHigh: require("./img/Preschool/preschool_concept_04_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Preschool/preschool_concept_05_low.jpg"),
        imgHigh: require("./img/Preschool/preschool_concept_05_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Preschool/preschool_concept_06_low.jpg"),
        imgHigh: require("./img/Preschool/preschool_concept_06_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Preschool/preschool_concept_07_low.jpg"),
        imgHigh: require("./img/Preschool/preschool_concept_07_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Preschool/preschool_concept_08_low.jpg"),
        imgHigh: require("./img/Preschool/preschool_concept_08_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Preschool/preschool_concept_09_low.jpg"),
        imgHigh: require("./img/Preschool/preschool_concept_09_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Preschool/preschool_concept_10_low.jpg"),
        imgHigh: require("./img/Preschool/preschool_concept_10_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Preschool/preschool_concept_11_low.jpg"),
        imgHigh: require("./img/Preschool/preschool_concept_11_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Preschool/preschool_concept_12_low.jpg"),
        imgHigh: require("./img/Preschool/preschool_concept_12_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Preschool/preschool_concept_13_low.jpg"),
        imgHigh: require("./img/Preschool/preschool_concept_13_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Preschool/preschool_concept_14_low.jpg"),
        imgHigh: require("./img/Preschool/preschool_concept_14_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Preschool/preschool_concept_15_low.jpg"),
        imgHigh: require("./img/Preschool/preschool_concept_15_high.jpg"),
        description: ( "" )
    }
];

export const TitoGallery = [
    {
        imgLow: require("./img/Tito/tito_concept_01_low.jpg"),
        imgHigh: require("./img/Tito/tito_concept_01_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Tito/tito_concept_02_low.jpg"),
        imgHigh: require("./img/Tito/tito_concept_02_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Tito/tito_concept_03_low.jpg"),
        imgHigh: require("./img/Tito/tito_concept_03_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Tito/tito_concept_04_low.jpg"),
        imgHigh: require("./img/Tito/tito_concept_04_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Tito/tito_concept_05_low.jpg"),
        imgHigh: require("./img/Tito/tito_concept_05_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Tito/tito_concept_06_low.jpg"),
        imgHigh: require("./img/Tito/tito_concept_06_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Tito/tito_concept_07_low.jpg"),
        imgHigh: require("./img/Tito/tito_concept_07_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Tito/tito_concept_08_low.jpg"),
        imgHigh: require("./img/Tito/tito_concept_08_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Tito/tito_concept_09_low.jpg"),
        imgHigh: require("./img/Tito/tito_concept_09_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Tito/tito_concept_10_low.jpg"),
        imgHigh: require("./img/Tito/tito_concept_10_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Tito/tito_concept_11_low.jpg"),
        imgHigh: require("./img/Tito/tito_concept_11_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Tito/tito_concept_12_low.jpg"),
        imgHigh: require("./img/Tito/tito_concept_12_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Tito/tito_concept_13_low.jpg"),
        imgHigh: require("./img/Tito/tito_concept_13_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Tito/tito_concept_14_low.jpg"),
        imgHigh: require("./img/Tito/tito_concept_14_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Tito/tito_concept_15_low.jpg"),
        imgHigh: require("./img/Tito/tito_concept_15_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Tito/tito_concept_16_low.jpg"),
        imgHigh: require("./img/Tito/tito_concept_16_high.jpg"),
        description: ( "" )
    },
    {
        imgLow: require("./img/Tito/tito_concept_17_low.jpg"),
        imgHigh: require("./img/Tito/tito_concept_17_high.jpg"),
        description: ( "" )
    }    
];

