import "./sectionContextMenu.scss"
import { React, useState, useEffect} from 'react'
import { useNavigate  } from "react-router-dom";

import { HomeGallery } from '../../galleryImg.js'
import { RULES } from '../../settings.js'

import CredentialsPopUp from '../credentialsPopUp/CredentialsPopUp.jsx'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import LockIcon from '@mui/icons-material/Lock';

const SectionContextMenu = ({section}) => {
    const url = window.location.pathname;
    const currentSection = url.split( '/' )[1];
    const currentProject = url.split( '/' )[2];

    const [ask, setAsk] = useState(false)
    const [currentItem, setCurrentItem] = useState({})
    const [password, setPassword] = useState(localStorage.getItem('password'))
    
    const navigate = useNavigate()

    const setUnlockedStatus = (url) => {
        if ( password in RULES ) {
            if ( RULES[password]["block"].includes(url) ) {
                return false
            } else {
                return true
            }
        }
        return false
    }

    var [listOptions, setListOptions] = useState( [] )
    var [openContextMenu, setOpenContextMenu] = useState( false )
    const getSections = ((e) => {
        let list = []
        HomeGallery.map((project) => {
            if (project?.section === section) {
                list.push({
                    "name": project?.description,
                    "link": project?.link,
                    "hidden": project?.hidden,
                    "unlocked": setUnlockedStatus(project.link)
                })
            }
        })

        if ( list.length === 0 ) {
            navigate("/" + section)
        } else {
            if ( list.length === 1 ) {
                navigate(list[0]?.link || "/")
            } else {
                if (openContextMenu) {
                    setOpenContextMenu(false)
                } else {
                    setListOptions(list)
                    setOpenContextMenu(true)
                }
            }
            
        }
    })
    
    const openProject = (item) => {
        if (item.hidden) {
            if (item.unlocked) {
                navigate(item.link)
            } else {
                setCurrentItem(item)
                setAsk(true)
            }
        } else {
            navigate(item.link)
        }
    }
    
    var sectionHighlight = false
    if (section === currentSection) {
        sectionHighlight = true
    }

    return (
        <>
            <div className={ ask ? "credentialsContainer ask" : "credentialsContainer"}>
                <div className="credentialBG"  onClick={() => {setAsk(false)}}/>
                <div className="credentials"><CredentialsPopUp currentItem={currentItem}/></div>
            </div>
            <div className="sectionButtonArea">
                <div className="sectionButton" onClick= {() => getSections(section)}>
                    <p>{section.toUpperCase()}</p>
                    <hr className={sectionHighlight? "sectionHr open" : "sectionHr"} />
                </div>
            </div>
            <div className={openContextMenu? "sectionContextMenu open" : "sectionContextMenu"}>
                {listOptions.map((project, index) => {
                    return(
                        <div className="projectButton" key={index} onClick={() => {openProject(project)}}>
                            <div className="projectButtonContent">
                                <div className="project">
                                    <div className={ project.hidden ? "projectLocked" : "projectLocked hidden"}>
                                        <div className={ project.unlocked ? "unLocked true" : "unLocked"}>
                                            <LockIcon className="icon"/>
                                        </div>
                                        <div className={ project.unlocked ? "unLocked" : "unLocked true"}>
                                            <LockOpenOutlinedIcon className="icon"/>
                                        </div>
                                    </div>

                                    <label className="projectName">{
                                        (project.hidden === false || project.unlocked) ? project.name : "Project #0" + (index+1)}
                                    </label>
                                </div>
                                <hr className={project.link === url ? "projectHR true" : "projectHR"}/>
                            </div>
                        </div>
                    )
                })}
            </div>
        </>
    )
};

export default SectionContextMenu