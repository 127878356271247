import './noMatch.scss'
import { useNavigate  } from "react-router-dom";
import { React, useEffect} from 'react'

import Navbar from '../../components/navbar/Navbar';
import Bottombar from '../../components/bottombar/Bottombar';

const NoMatch = () => {
    const url = window.location.pathname;
    const navigate = useNavigate()
    
    useEffect(() => {
        if (url === "/" || url === "") {
            navigate("/home")
        }
    },[url, navigate])

    return (
        <div className="page">

            <div className="top">
                <Navbar/>
            </div>

            <div className="mainContainer">

                <div className="content">
                    <h1>No match route</h1>
                </div>

                <div className="bottom">
                    <Bottombar/>
                </div>

            </div>
        </div>
    );
};


export default NoMatch